// use next dynamic
import dynamic from 'next/dynamic';

import { ModuleScheduleItem, ModuleScheduleSection, PlatformID } from '@common/clients/api';

import { Container } from './atoms';

import styles from './ArticlePromoItem.module.scss';

const ArticlePromo = dynamic(() =>
    import('@web/molecules/ArticlePromoItem/atoms/ArticlePromo').then((module) => module.ArticlePromo),
);
const CommentPromo = dynamic(() =>
    import('@web/molecules/ArticlePromoItem/atoms/CommentPromo').then((module) => module.CommentPromo),
);
const HomePromo = dynamic(() =>
    import('@web/molecules/ArticlePromoItem/atoms/HomePromo').then((module) => module.HomePromo),
);

export interface Props {
    hasDarkBackground?: boolean;
    moduleScheduleItem: ModuleScheduleItem;
    platform?: PlatformID;
}

export const ArticlePromoItem = ({ moduleScheduleItem, hasDarkBackground, platform }: Props) => {
    const { url, module, bookmaker } = moduleScheduleItem;
    const modulePlacement = module || ModuleScheduleSection.PROMO_ELEMENT_BELOW_ARTICLE;
    const icon = hasDarkBackground ? bookmaker?.darkBackgroundIcon : bookmaker?.lightBackgroundIcon;

    const itemClasses = [styles.ArticlePromoItem];
    itemClasses.push(styles[`${modulePlacement}-item`]);

    const backgroundColor = bookmaker?.backgroundColor;

    return (
        <Container
            itemClasses={itemClasses}
            backgroundColor={backgroundColor}
            bookmaker={bookmaker}
            moduleScheduleItem={moduleScheduleItem}
        >
            {modulePlacement === ModuleScheduleSection.ODDS_DOSSIER_HOME ? (
                <HomePromo moduleScheduleItem={moduleScheduleItem} url={url || ''} icon={icon || ''} />
            ) : modulePlacement === ModuleScheduleSection.COMMENTS ? (
                <CommentPromo moduleScheduleItem={moduleScheduleItem} url={url || ''} />
            ) : (
                <ArticlePromo
                    platform={platform}
                    moduleScheduleItem={moduleScheduleItem}
                    url={url || bookmaker?.url || ''}
                    icon={icon || ''}
                />
            )}
        </Container>
    );
};
