import { ReactElement, useMemo, useRef } from 'react';
import dynamic from 'next/dynamic';

import { AdPlacement, ModuleScheduleSection, PlatformID } from '@common/clients/api';
import { Ad } from '@web/molecules/Ad';
import { HeadlinesDossier } from '@web/molecules/HeadlinesDossier';
import { Injection, isInjection } from '@web/molecules/NewsList';
import { DisplayType } from '@web/molecules/NewsList/NewsList.types';
import { TinyMatchBlock } from '@web/organisms/TinyMatchBlock';
import { VideoCarousel as OnScrollVideoCarousel } from '@web/organisms/VideoCarousel/OnScrollLoadedVideoCarousel';
import { NewsListInsert } from '@web/templates/HomePage/organisms';

import { shouldInclude } from '../utils';
import { Props } from './HomeNewsList';

const OddsDossierIntro = dynamic(() =>
    import('@web/molecules/OddsDossierIntro').then((module) => module.OddsDossierIntro),
);

/**
 * Create a ref array of <Ad/> and other injections for use in the NewsList.
 *
 * @example
 * injectedItems[increment.current] = <HeadlinesDossier
 *     dossier={headlinesDossier}
 *     />;
 *
 * @info `useRef` is used to prevent re-rendering when increment is updated
 * */
export const useInjections = (
    platform: PlatformID,
    props: Partial<Props>,
    displayType: DisplayType,
): Array<ReactElement | Injection> => {
    const { headlinesDossier, oddsDossier } = props;

    /** Ref to increment injected index **/
    const increment = useRef<number>(0);

    const injections = useMemo(() => {
        const injectedItems: (ReactElement | Injection)[] = [];

        increment.current = 0;
        if (oddsDossier && shouldInclude(platform, NewsListInsert.ODDS_DOSSIER)) {
            increment.current += 5;
            if (oddsDossier.tag) oddsDossier.tag.title = '';
            if (platform === PlatformID.VI && oddsDossier.matches) {
                const match = oddsDossier.matches[0];
                const bookmaker = oddsDossier?.items?.length ? oddsDossier.items[0]?.bookmaker : undefined;

                injectedItems[increment.current] = (
                    <TinyMatchBlock
                        trackerName={ModuleScheduleSection.ODDS_DOSSIER_HOME}
                        match={match}
                        bookmaker={bookmaker}
                    />
                );
            } else {
                injectedItems[increment.current] = (
                    <OddsDossierIntro
                        trackerName={ModuleScheduleSection.ODDS_DOSSIER_HOME}
                        {...oddsDossier}
                    />
                );
            }
        }

        if (headlinesDossier && shouldInclude(platform, NewsListInsert.HEADLINES_DOSSIER)) {
            increment.current += 5;
            injectedItems[increment.current] = <HeadlinesDossier {...headlinesDossier} />;
        }

        if (shouldInclude(platform, NewsListInsert.VIDEO_CAROUSEL)) {
            increment.current += 5;
            injectedItems[increment.current] = (
                <>
                    <OnScrollVideoCarousel />
                    <Ad placement={AdPlacement.HOME_AFTER_PLAYER} isOptional />
                </>
            );

            increment.current += 5;
            injectedItems[increment.current] = (
                <Ad placement={AdPlacement.HOME_AFTER_PLAYER_AFTER_ARTICLE_5} />
            );
        }

        const placements: { fullWidth: boolean; placement: AdPlacement }[] = [];

        if (displayType === DisplayType.blocks) {
            placements[9] = { fullWidth: true, placement: AdPlacement.HOME_AFTER_ARTICLE_10 };
            placements[18] = { fullWidth: true, placement: AdPlacement.HOME_AFTER_ARTICLE_20 };
            placements[27] = { fullWidth: true, placement: AdPlacement.HOME_AFTER_ARTICLE_30 };
            placements[36] = { fullWidth: true, placement: AdPlacement.HOME_AFTER_ARTICLE_40 };
            placements[45] = { fullWidth: true, placement: AdPlacement.HOME_AFTER_ARTICLE_50 };
            placements[54] = { fullWidth: true, placement: AdPlacement.HOME_AFTER_ARTICLE_60 };
        } else {
            placements[10] = { fullWidth: false, placement: AdPlacement.HOME_AFTER_ARTICLE_10 };
            placements[20] = { fullWidth: false, placement: AdPlacement.HOME_AFTER_ARTICLE_20 };
            placements[30] = { fullWidth: false, placement: AdPlacement.HOME_AFTER_ARTICLE_30 };
            placements[40] = { fullWidth: false, placement: AdPlacement.HOME_AFTER_ARTICLE_40 };
            placements[50] = { fullWidth: false, placement: AdPlacement.HOME_AFTER_ARTICLE_50 };
            placements[60] = { fullWidth: false, placement: AdPlacement.HOME_AFTER_ARTICLE_60 };
        }

        placements.forEach((placement, index) => {
            const item = injectedItems[index];

            injectedItems[index] = item
                ? {
                      element: (
                          <>
                              <Ad placement={placement.placement} />
                              {isInjection(item) ? item.element : item}
                          </>
                      ),
                      fullWidth: placement.fullWidth,
                  }
                : {
                      element: <Ad placement={placement.placement} />,
                      fullWidth: placement.fullWidth,
                  };
        });

        return injectedItems;
    }, [platform, oddsDossier, headlinesDossier, displayType]);

    return injections;
};
