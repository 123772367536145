import { memo } from 'react';

import { Icon } from '@common/atoms/Icon';
import { HeadlinesDossier as HeadlinesDossierType } from '@common/clients/api';
import { InternalLink, Route } from '@web/atoms/InternalLink';

import styles from './HeadlinesDossier.module.scss';

export interface Props extends HeadlinesDossierType {}

const Component = ({ preTitle, title, subTitle, articles }: Props) => (
    <aside className={[styles.HeadlinesDossier, 'inverted'].join(' ')}>
        <h3>{preTitle}</h3>
        <h1>{title}</h1>
        <p className={styles['sub-title']}>{subTitle}</p>
        <ul>
            {articles.map((article, index) => (
                <li key={`article-${index}`}>
                    <InternalLink route={Route.Article} query={{ slug: article.newsID }}>
                        <span className={styles.radio} />
                        <h4>{article.newsTitle}</h4>
                        {article.commentCount ? (
                            <span className={styles['comments-indicator']}>
                                <Icon.comment />
                                <span className={styles['comment-count']}>{article.commentCount}</span>
                            </span>
                        ) : null}
                    </InternalLink>
                </li>
            ))}
        </ul>
    </aside>
);

export const HeadlinesDossier = memo(Component);
