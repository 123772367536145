import { CSSProperties, ReactNode } from 'react';

import { Bookmaker, ModuleScheduleItem, ModuleScheduleSection } from '@common/clients/api';
import { BettingIntegrationTracker } from '@web/atoms/BettingIntegrationTracker';
import { BookmakerLogo } from '@web/atoms/BookmakerLogo';

import styles from '../ArticlePromoItem.module.scss';

interface ContainerProps {
    backgroundColor?: string;
    bookmaker?: Bookmaker;
    children: ReactNode;
    itemClasses: string[];
    moduleScheduleItem: ModuleScheduleItem;
    modulePlacement?: ModuleScheduleSection;
}

export const Container = ({
    backgroundColor,
    bookmaker,
    children,
    itemClasses,
    moduleScheduleItem,
    modulePlacement,
}: ContainerProps) => {
    const url = moduleScheduleItem.url || moduleScheduleItem.bookmaker?.url || '';
    return (
        <aside
            className={itemClasses.join(' ')}
            style={backgroundColor ? ({ '---backgroundColor': backgroundColor } as CSSProperties) : {}}
        >
            {modulePlacement === ModuleScheduleSection.COMMENTS ? (
                <div className={styles['betting-provider-logo']}>
                    <BookmakerLogo
                        className={styles['bookmaker-logo']}
                        alt={moduleScheduleItem.bookmaker?.name || ''}
                        url={url}
                        src={moduleScheduleItem.bookmaker?.darkBackgroundIcon || ''}
                    />
                </div>
            ) : null}
            <div
                className={`${styles['article-promo-item']} article-promo-item ndm-betting-integration-tracker`}
            >
                {children}
            </div>
            {bookmaker?.id ? (
                <BettingIntegrationTracker bookmakerID={bookmaker?.id} module={moduleScheduleItem.module} />
            ) : null}
        </aside>
    );
};
